import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import montageImage from './image/eric_montage.png'
import newWorkImage from './image/newwork.png'
import contactImage from './image/contact.png'

function Home() {
    return (
        <section className='homeSection'>
            <div>
                <div className='home-intro'>
                    <div className='home-montage-image'>
                        <img src={montageImage} alt='Eric Camiel Montage' />
                    </div>
                </div>
                <div className='home-other'>
                    <div className='home-links'>
                        <div className='link1'>
                            <Nav.Link id='homeLink' className='headerNavHome' as={Link} to={'/new_work'} href="#home">
                                <img className="thumbImage" src={newWorkImage} alt="logo" />
                            </Nav.Link>
                            <Link id="home-btn" className="btn" to={'/new_work'}>Selected Work</Link>
                        </div>
                        <div className='link2'>
                            <Nav.Link id='homeLink' className='headerNavHome' as={Link} to={'/contact'} href="#home">
                                <img className="thumbImage" src={contactImage} alt="logo" />
                            </Nav.Link>
                            <Link id="home-btn" className="btn" to={'/contact'}>Contact</Link>
                        </div>
                    </div>
                    <div className='home-intro-data'>
                        <p>Eric Camiel has had a distinguished career in the visual arts. His architecture and design work has been on the cover of House Beautiful
                            and several of his works in other media are in the permanent collections of the Museum of Modern Art, The Library of Congress and the
                            Library at Lincoln Center. His film work has won multiple Emmys and an Academy Award nomination. His work has been reviewed in the New York Times,
                            Glamour, Time, and many more. Time magazine's ”Man of the Year” article also featured his work. <br></br><br></br>
                            His prize winning sculpture has been pictured in the Sunday New York Times and has been exhibited in many museums and galleries.</p>
                    </div>
                    {/* <Link id="home-btn" className="btn" to={'/new_work'}>Take a look at my work</Link> */}
                </div>
            </div >
        </section >
    )
}

export default Home
