import './App.css';
import Portfolio from './components/Portfolio/Portfolio';
// import { useEffect } from 'react';
// import ReactGA from 'react-ga';


function App() {
  // ReactGA.initialize('G-2WLTVBN11W');

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   console.log('GA initialized');
  //   console.log("path: " + window.location.pathname + window.location.search)
  // }, []);

  return (
    /* The application starts here, where we call a componenet Portfolio, which handles routing and navigation */
    <Portfolio></Portfolio>
  );
}

export default App;
