import React from 'react';
import { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import 'react-circular-progressbar/dist/styles.css';
import './Gallery6.css'
import '../../styles/style.css'

//compressed images
import thumbnail1 from './thumbnails/1_the_ancients-min.jpg'
import thumbnail2 from './thumbnails/2_wooden_watch-min.jpg'
import thumbnail3 from './thumbnails/3_watch_over_me-min.jpg'
import thumbnail4 from './thumbnails/4_watch_over_me-min.jpg'
import thumbnail5 from './thumbnails/5_watch_over_me-min.jpg'
import thumbnail6 from './thumbnails/6_watch_over_me-min.jpg'
import thumbnail7 from './thumbnails/7_ancient_blue_eye-min.jpg'
import thumbnail8 from './thumbnails/8_the_ancients-min.jpg'
import thumbnail9 from './thumbnails/9_watch_over_me-min.jpg'
import thumbnail10 from './thumbnails/10_watch_japanese.jpg'
import thumbnail11 from './thumbnails/11_watch_over_me.jpg'

//side iamges
import related1 from './related_thumbs/1_1_ancient_side.jpg'
import related1_1 from './related_thumbs/1_2_ancient_side.jpg'
import related2 from './related_thumbs/2_1_wooden_watch_closeup_optimized.jpg'
import related2_1 from './related_thumbs/2_2_woode_watch_left_optimized.jpg'
import related3 from './related_thumbs/3_1_watch_over_me_side_optimized.jpg'
import related5 from './related_thumbs/5_1_watch_over_me.jpg'
import related8 from './related_thumbs/8_1_the_ancients_close_optimized.jpg'
import realted10 from './related_thumbs/10_watch_japanese_related-min.jpg'
import realted11 from './related_thumbs/11_watch_over_me_zoom.jpg'

function GallerySix() {


    const [relatedImage, setRelatedImages] = useState([related1, related1_1]);
    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('The Ancients');
    const [displayImageSize, setDisplayImageSize] = useState('7" x 6" x 4"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Distressed Paint Finish');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = [related1, related1_1]
    const relatedImages2 = [related2, related2_1]
    const relatedImages3 = [related3]
    const relatedImages4 = []
    const relatedImages5 = [related5]
    const relatedImages6 = []
    const relatedImages7 = []
    const relatedImages8 = [related8]
    const relatedImages9 = []
    const relatedImages10 = [realted10]
    const relatedImages11 = [realted11]

    function delayRender() {
        setTimeout(() => {
            console.log("loading is false")

            const progressBar = document.getElementsByClassName("loadingContainer");
            const mainImageGrid = document.getElementsByClassName("mainGallerySixContainer");
            progressBar[0].style.display = "none";
            mainImageGrid[0].style.display = "block";

        }, 1500);
    }

    delayRender();

    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
        if (mainImageOnDisplay == 2 && element.target.id == 0) {
            console.log('came')
            setDisplayImageHeading('Wooden Watch Over Me (Closeup)')
            setCurrentDisplayImage(related2)
        }
        else if (mainImageOnDisplay == 2 && element.target.id == 1) {
            setDisplayImageHeading('Wooden Watch Over Me (Other Side)')
            setCurrentDisplayImage(related2_1)
        }
        else if (mainImageOnDisplay == 3 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me (Other Side)')
            setCurrentDisplayImage(related3)
        }
        else if (mainImageOnDisplay == 5 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me (Other Side)')
            setCurrentDisplayImage(related5)
        }
        else if (mainImageOnDisplay == 8 && element.target.id == 0) {
            setDisplayImageHeading('The Ancient (Other Angle)')
            setCurrentDisplayImage(related8)
        }
        else if (mainImageOnDisplay == 10 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me Japanese (Other Side)')
            setCurrentDisplayImage(realted10)
        }
        else if (mainImageOnDisplay == 11 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me (Zoom)')
            setCurrentDisplayImage(realted11)
        }
    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('The Ancients')
                setDisplayImageSize('7" x 6" x 64"')
                setDisplayImageMaterial('Stainless Steel, Arcylic, Coppper')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Wooden Watch Over Me')
                setDisplayImageSize('24" x 41" x 8"')
                setDisplayImageMaterial('Mahogany, Bronze, Patinas')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('72" x 28" x 8"')
                setDisplayImageMaterial('Stainless Steel, Aluminum, Fiberglass, Paint')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('24" x 10" x 4"')
                setDisplayImageMaterial('Bronze')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('30" x 10" x 4"')
                setDisplayImageMaterial('Stainless Steel & Copper')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('24" x 10" x 3"')
                setDisplayImageMaterial('Stainless Steel & Bronze')
                break;
            case '7':
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('The Ancients')
                setDisplayImageSize('7" x 6" x 24"')
                setDisplayImageMaterial('Distressed Paint Finish')
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('The Ancients')
                setDisplayImageSize('7" x 6" x 24"')
                setDisplayImageMaterial('Distressed Paint Finish')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('24" x 10" x 3"')
                setDisplayImageMaterial('Stainless Steel & Bronze')
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Watch Over Me Japanese')
                setDisplayImageSize('108" (9 feet) x 42" x 7"')
                setDisplayImageMaterial('Stainless Steel, Fiberglass, Paint')
                break;
            case '11':
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('72" x 28" x 8"')
                setDisplayImageMaterial('Stainless Steel, Aluminum, Fiberglass, Paint')
                break;
            default:
                setRelatedImages([])
                break;
        }
    }

    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }

    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return (

        <div className="gallerySix">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathTransitionDuration: 0.5,
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>
            {/* <LoadingContainer></LoadingContainer> */}

            {/*This is for DESKTOP VIEW*/}
            <div className='mainGallerySixContainer'>
                <div className='gallerySixContainer'>
                    <div className='firstContainer'>
                        <div className='row-tiny'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='gallery6'></img>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='gallery6'></img>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='gallery6'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='4' onMouseOver={(e) => updateImage(e)} src={thumbnail4} alt='gallery6'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='gallery6'></img>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='gallery6'></img>
                            <img className='imageCard' id='9' onMouseOver={(e) => updateImage(e)} src={thumbnail9} alt='gallery6'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} src={thumbnail7} alt='gallery6'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='gallery6'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} src={thumbnail10} alt='gallery6'></img>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} src={thumbnail11} alt='gallery6'></img>
                        </div>
                    </div>
                    <div className='secondContainer'>

                        <div className='displayImageContainer'>
                            <div className='displayImageOne'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                            </div>
                            <div className='displayImageTwo'>
                                <div className='related_images'>
                                    {relatedImage.map((url, index) => {
                                        return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                    })}
                                </div>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <h6 id='display_image_size'>{displayImageSize}</h6>
                                    <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail4} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail8} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_9' src={thumbnail9} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                    <img className='imageCardMobile' id='mobile_11' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='gallery6'></img>
                </div>
            </div>

        </div>

    )

}

export default GallerySix;
