import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Container, NavDropdown, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavigationBar.css';
import { useLocation } from 'react-router-dom';
import titleImage from './name.png'
import ReactGA from 'react-ga';

function NavigationBarHeader() {

  //when document loads, get current path and set active class
  const location = useLocation();

  const routes = [
    { path: '/', state: 'active_home' },
    { path: '/about', state: 'active_about' },
    { path: '/contact', state: 'active_contact' },
    { path: '/new_work', state: 'active_newWorks' },
    { path: '/gallery_1', state: 'active_One' },
    { path: '/gallery_2', state: 'active_Two' },
    { path: '/gallery_3', state: 'active_Three' },
    { path: '/gallery_4', state: 'active_Four' },
    { path: '/gallery_5', state: 'active_Five' },
    { path: '/gallery_6', state: 'active_Six' },
    { path: '/gallery_7', state: 'active_Seven' },
    { path: '/gallery_8', state: 'active_Eight' }
  ];

  const setActiveState = (path) => {
    return routes.reduce((state, route) => {
      state[route.state] = route.path === path;
      return state;
    }, {});
  };

  const initialState = setActiveState(location.pathname);
  const [activeStates, setActiveStates] = useState(initialState);

  useEffect(() => {
    ReactGA.initialize('G-2WLTVBN11W');
    ReactGA.pageview(location.pathname);

    const newActiveStates = setActiveState(location.pathname);
    setActiveStates(newActiveStates);
  }, [location]);

  return (
    <div>
      <div className='topNameHeader' >
        <Nav.Link id='home' className='headerNavHome' as={Link} to={'/'} href="#home">
          <img className="nav-header-one" src={titleImage} alt="logo" />
        </Nav.Link>
      </div>
      <div className='navBarContainerTopDiv'>
        <Container className='topNavContainer' >
          <Navbar className='navBar' variant='light' expand="md">
            <Container className='navBarContainer'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">

                <Nav className="m-auto">
                  <Nav.Link
                    id="home"
                    className={activeStates.active_home ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/"}
                    href="#home"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    id="newWorks"
                    className={activeStates.active_newWorks ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/new_work"}
                    href="#newWorks"
                  >
                    Selected Works
                  </Nav.Link>
                  <Nav.Link
                    id="One"
                    className={activeStates.active_One ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_1"}
                    href="#One"
                  >
                    Rough Stuff
                  </Nav.Link>
                  <Nav.Link
                    id="Two"
                    className={activeStates.active_Two ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_2"}
                    href="#Two"
                  >
                    Figurative
                  </Nav.Link>
                  <Nav.Link
                    id="Three"
                    className={activeStates.active_Three ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_3"}
                    href="#Three"
                  >
                    Dreams
                  </Nav.Link>
                  <Nav.Link
                    id="Four"
                    className={activeStates.active_Four ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_4"}
                    href="#Four"
                  >
                    Exterior
                  </Nav.Link>
                  <Nav.Link
                    id="Five"
                    className={activeStates.active_Five ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_5"}
                    href="#Five"
                  >
                    Dancers
                  </Nav.Link>
                  <Nav.Link
                    id="Six"
                    className={activeStates.active_Six ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_6"}
                    href="#Six"
                  >
                    The Ancients
                  </Nav.Link>
                  <Nav.Link
                    id="Seven"
                    className={activeStates.active_Seven ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_7"}
                    href="#Seven"
                  >
                    Small Stuff
                  </Nav.Link>
                  <Nav.Link
                    id="Eight"
                    className={activeStates.active_Eight ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/gallery_8"}
                    href="#Eight"
                  >
                    Big Stuff
                  </Nav.Link>
                  <Nav.Link
                    id="about"
                    className={activeStates.active_about ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/about"}
                    href="#about"
                  >
                    About
                  </Nav.Link>
                  <Nav.Link
                    id="contact"
                    className={activeStates.active_contact ? "navLinkActive" : "navLink"}
                    as={Link}
                    to={"/contact"}
                    href="#contact"
                  >
                    Contact
                  </Nav.Link>
                </Nav>

              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>

        <div className="navBarMobile">
          <Navbar className='navBar' variant='light' expand="md">
            <Container className='navBarContainer'>

              <DropdownButton id="dropdown-basic-button" title="More Sculptures">
                <Nav.Link
                  id="home"
                  className={activeStates.active_home ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/"}
                  href="#home"
                >
                  Home
                </Nav.Link>
                <Dropdown.Item
                  id="newWorks"
                  className={activeStates.active_newWorks ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/new_work"}
                  href="#newWorks"
                >
                  Selected Works
                </Dropdown.Item>
                <Dropdown.Item
                  id="One"
                  className={activeStates.active_One ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_1"}
                  href="#One"
                >
                  Rough Stuff
                </Dropdown.Item>
                <Dropdown.Item
                  id="Two"
                  className={activeStates.active_Two ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_2"}
                  href="#Two"
                >
                  Figurative
                </Dropdown.Item>
                <Dropdown.Item
                  id="Three"
                  className={activeStates.active_Three ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_3"}
                  href="#Three"
                >
                  Dreams
                </Dropdown.Item>
                <Dropdown.Item
                  id="Four"
                  className={activeStates.active_Four ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_4"}
                  href="#Four"
                >
                  Exterior
                </Dropdown.Item>
                <Dropdown.Item
                  id="Five"
                  className={activeStates.active_Five ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_5"}
                  href="#Five"
                >
                  Dancers
                </Dropdown.Item>
                <Dropdown.Item
                  id="Six"
                  className={activeStates.active_Six ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_6"}
                  href="#Six"
                >
                  The Ancients
                </Dropdown.Item>
                <Dropdown.Item
                  id="Seven"
                  className={activeStates.active_Seven ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_7"}
                  href="#Seven"
                >
                  Small Stuff
                </Dropdown.Item>
                <Dropdown.Item
                  id="Eight"
                  className={activeStates.active_Eight ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/gallery_8"}
                  href="#Eight"
                >
                  Big Stuff
                </Dropdown.Item>
                <Nav.Link
                  id="contact"
                  className={activeStates.active_contact ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/contact"}
                  href="#contact"
                >
                  Contact
                </Nav.Link>
                <Dropdown.Item
                  id="about"
                  className={activeStates.active_about ? "navLinkActive" : "navLink"}
                  as={Link}
                  to={"/about"}
                  href="#about"
                >
                  About Me
                </Dropdown.Item>
              </DropdownButton>

            </Container>
          </Navbar>
        </div>

      </div>

    </div>
  )
}

export default NavigationBarHeader
