import React from 'react'
import './Contact.css'
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import contactImage from './contact.jpg'

function Contact() {
    return (
        <address>
            <div>
                <section className='contactSection' >
                    <div className='contact-container'>
                        <div className='contact-image'>
                            <img className='cimage' src={contactImage} alt='contact' />
                        </div>
                        <div className='contact-data'>
                            <h2 id="contact-title-tag" >Get in touch for any queries or to view my work</h2>
                            <h3>Eric Camiel <br></br>
                                210 Middle River Road, <br></br> Danbury, CT 06811
                            </h3>
                            <h4><a id='contact-info-a' href="tel:203-792-0382"><FiPhoneCall id='phone-icon' />(203) 792-0382</a></h4>
                            <h4><a id='contact-info-a' href="mailto:ericcamiel@gmail.com"><MdOutlineMailOutline id='email-icon' />ericcamiel@gmail.com</a></h4>
                        </div>
                    </div>

                    <div className='contact-container-mobile' >
                        <div className='contact-image-mobile'>
                            <img className='cimage-mobile' src={contactImage} alt='contact' />
                        </div>
                        <div className='contact-data-mobile'>
                            <h3 id="contact-title-tag-mobile" >Get in touch for any queries or to view my work</h3>
                            <h4 id="h4-name-mobile">Eric Camiel <br></br>
                                210 Middle River Road, <br></br> Danbury, CT 06811
                            </h4>
                            <h5><a id='contact-info-a-mobile' href="tel:203-792-0382"><FiPhoneCall id='phone-icon' />(203) 792-0382</a></h5>
                            <h5><a id='contact-info-a-mobile' href="mailto:ericcamiel@gmail.com"><MdOutlineMailOutline id='email-icon' />ericcamiel@gmail.com</a></h5>
                        </div>
                    </div>

                </section>
            </div>
        </address>
    )
}

export default Contact
