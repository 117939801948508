import React from 'react'
import './About.css'
//import YoutubeEmbed Component
import YoutubeEmbed from "../../components/VideoHandler/YoutubeEmbed";
import ReactPlayer from "react-player"

function About() {

    const [fullScreen, setFullScreen] = React.useState(false);

    //function to handle onclick for div and make it full screen
    function handlePlayer(e) {
        console.log("played");
        const videoPlayer = document.querySelector(".about-intro-image");
        videoPlayer.requestFullscreen();
        setFullScreen(true);
    }

    function handlePaused(e) {
        console.log("paused");
        setFullScreen(false);
        document.exitFullscreen()
    }

    function handleEnded(e) {
        console.log("ended");
        setFullScreen(false);
        document.exitFullscreen()
    }


    return (
        <div>
            <section className='aboutSection'>
                <div className='about-intro'>
                    <div className='about-intro-image'>
                        {fullScreen ? <ReactPlayer
                            style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", }}
                            onPlay={(e) => {
                                handlePlayer(e);
                            }}
                            onPause={(e) => {
                                handlePaused(e);
                            }}
                            onEnded={(e) => {
                                handleEnded(e);
                            }}
                            on
                            width="100%"
                            height="100%"
                            controls={true}
                            url="https://www.youtube.com/watch?v=9RYCNwnMD5Y&ab_channel=TheAvenueConcept"
                        /> : <ReactPlayer
                            style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", }}
                            onPlay={(e) => {
                                handlePlayer(e);
                            }}
                            onPause={(e) => {
                                handlePaused(e);
                            }}
                            onEnded={(e) => {
                                handleEnded(e);
                            }}
                            width="510px"
                            height="310px"
                            controls={true}
                            url="https://www.youtube.com/watch?v=9RYCNwnMD5Y&ab_channel=TheAvenueConcept"
                        />}
                    </div>
                    <div className='about-intro-data'>
                        <p>I was born and raised in Brooklyn, New York, and passed my young summers in a community in Connecticut.
                            I was an English major and art history minor at the University of Pennsylvania and was in the master’s program at New York University Film School
                            where I won the National Student Film festival. I now live in a house in Connecticut which I designed and built.</p>
                        <h2 className='about-name'>- Eric Camiel</h2>
                    </div>
                </div>

                <div className='mobile-about-intro'>
                    <div className='about-intro-image-mobile'>
                        <ReactPlayer
                            style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", }}
                            onPlay={(e) => {
                                handlePlayer(e);
                            }}
                            onPause={(e) => {
                                handlePaused(e);
                            }}
                            onEnded={(e) => {
                                handleEnded(e);
                            }}
                            on
                            width="90vw"
                            height="50vw"
                            controls={true}
                            url="https://www.youtube.com/watch?v=9RYCNwnMD5Y&ab_channel=TheAvenueConcept"
                        />
                    </div>
                    <div className='about-intro-data-mobile'>
                        <p>I was born and raised in Brooklyn, New York, and passed my young summers in a community in Connecticut.
                            I was an English major and art history minor at the University of Pennsylvania and was in the master’s program at New York University Film School
                            where I won the National Student Film festival. I now live in a house in Connecticut which I designed and built.</p>
                        <h2 className='about-name-mobile'>- Eric Camiel</h2>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
