import React from 'react'
import {
    HashRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import About from '../About/About';
import Contact from '../Contact/Contact';
import NewWork from '../Work/New-Work/NewWork';
import GalleryOne from '../Work/Gallery1/GalleryOne';
import GalleryTwo from '../Work/Gallery2/GalleryTwo';
import GalleryThree from '../Work/Gallery3/GalleryThree';
import GalleryFour from '../Work/Gallery4/GalleryFour';
import GalleryFive from '../Work/Gallery5/GalleryFive';
import GallerySix from '../Work/Gallery6/GallerySix';
import GallerySeven from '../Work/Gallery7/GallerySeven';
import GalleryEight from '../Work/Gallery8/GalleryEight';
import NavigationBarHeader from '../NavBar/NavigationBar';
import Home from '../Home/Home';
import './Portfolio.css'

/* Portfolio containers a router, which takes links from naviagionBarHeader and decides which div to display on bottom of the page*/
function Portfolio() {

    // //get path to current link
    // const path = window.location.pathname;
    // console.log("path: " + path);

    return (
        <Router basename='/' >
            <div className='portfolioContainer'>
                <div className='navBar'>
                    <NavigationBarHeader></NavigationBarHeader>
                </div>
                <div className='portfolio'>
                    <Routes>
                        <Route path='/about' element={<About />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/' element={<Home />} />
                        <Route path='/new_work' element={<NewWork />} />
                        <Route path='/gallery_1' element={<GalleryOne />} />
                        <Route path='/gallery_2' element={<GalleryTwo />} />
                        <Route path='/gallery_3' element={<GalleryThree />} />
                        <Route path='/gallery_4' element={<GalleryFour />} />
                        <Route path='/gallery_5' element={<GalleryFive />} />
                        <Route path='/gallery_6' element={<GallerySix />} />
                        <Route path='/gallery_7' element={<GallerySeven />} />
                        <Route path='/gallery_8' element={<GalleryEight />} />
                    </Routes>
                </div>
            </div>
        </Router>
    )
}

export default Portfolio
