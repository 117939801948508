import React from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ChangingProgressProvider from '../../ProgressBar/ChangingProgressProvider';
import { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './Gallery4.css'
import '../../styles/style.css'
import 'react-circular-progressbar/dist/styles.css';

//thumbnails
import thumbnail1 from './thumbnails/1_snake-min.jpg'
import thumbnail2 from './thumbnails/2_dancer_facing_top-min.jpg'
import thumbnail3 from './thumbnails/3_flag.jpg'
import thumbnail4 from './thumbnails/4_the_ancients-min.jpg'
import thumbnail5 from './thumbnails/5_red_flower-min.jpg'
import thumbnail6 from './thumbnails/6_rythem-min.jpg'
import thumbnail7 from './thumbnails/7_nude_descending-min.jpeg'
import thumbnail8 from './thumbnails/8_keep_on_trucking-min.jpg'
import thumbnail9 from './thumbnails/9_metropolis-min.jpg'
import thumbnail10 from './thumbnails/10_sail_dream-min.jpg'
import thumbnail11 from './thumbnails/11_bird_brain-min.jpg'
import thumbnail12 from './thumbnails/12_gate_main.jpg'
import thumbnail13 from './thumbnails/13_man_woman_blue-min.jpg'
import thumbnail14 from './thumbnails/14_freebird-min.jpg'
import thumbnail15 from './thumbnails/15_ancient.jpg'
import thumbnail16 from './thumbnails/16_watch_over_me-min.jpg'
import thumbnail17 from './thumbnails/17_watch_japanese.jpg'
import thumbnail18 from './thumbnails/18_watch_over_me.jpg'

//these are related thumbnails
import relatedthumbnail1 from './related_thumbs/1_1_snake_zoom-min.jpg'
import relatedthumbnail2 from './related_thumbs/2_dancer_facing_out-min.jpg'
import relatedthumbnail2_1 from './related_thumbs/2.1_dancer_facing-min.jpg'
import relatedthumbnail9 from './related_thumbs/9_1_metro_close.jpg'
import relatedthumbnail10 from './related_thumbs/10_sail_dream_other.jpg'
import relatedthumbnail12 from './related_thumbs/12_1_gate_related.jpg'
import relatedthumbnail12_1 from './related_thumbs/12_gate_related_1.jpg'
import relatedthumbnail13_1 from './related_thumbs/13_1_man_woman_blue_close-min.jpg'
import relatedthumbnail15_1 from './related_thumbs/15_1_ancient_side.jpg'
import relatedthumbnail15_2 from './related_thumbs/15_2_ancient_side.jpg'
import relatedthumbnail16_1 from './related_thumbs/16_1_watch_over_me_side_optimized.jpg'
import relatedthumbnail17_1 from './related_thumbs/17_1_watch_japanese_related-min.jpg'
import relatedthumbnail18_1 from './related_thumbs/18_watch_over_me_zoom.jpg'
// import relatedthumbnail3 from './related_thumbs/3_1_watch_over_me-min.jpg'


function GalleryFour() {

    const [currentDisplayImage, setCurrentDisplayImage] = useState(thumbnail1);
    const [mainImageOnDisplay, setMainImageOnDisplay] = useState(1);
    const [displayImageHeading, setDisplayImageHeading] = useState('Maroon');
    const [displayImageSize, setDisplayImageSize] = useState('57" x 27" x 12"');
    const [displayImageMaterial, setDisplayImageMaterial] = useState('Steel, Stainless Steel, Paint');
    const [fullScreen, setFullScreen] = useState(false);

    const relatedImages1 = [relatedthumbnail1]
    const relatedImages2 = [relatedthumbnail2, relatedthumbnail2_1]
    const relatedImages3 = []
    const relatedImages4 = []
    const relatedImages5 = []
    const relatedImages6 = []
    const relatedImages7 = []
    const relatedImages8 = []
    const relatedImages9 = [relatedthumbnail9]
    const relatedImages10 = [relatedthumbnail10]
    const relatedImages11 = []
    const relatedImages12 = [relatedthumbnail12, relatedthumbnail12_1]
    const relatedImages13 = [relatedthumbnail13_1]
    const relatedImages14 = []
    const relatedImages15 = [relatedthumbnail15_1, relatedthumbnail15_2]
    const relatedImages16 = [relatedthumbnail16_1]
    const relatedImages17 = [relatedthumbnail17_1]
    const relatedImages18 = [relatedthumbnail18_1]
    const [showLandscape, setShowLandscape] = useState(false);
    const [relatedImage, setRelatedImages] = useState(relatedImages1);

    function delayRender() {
        setTimeout(() => {
            console.log("loading is false")

            const progressBar = document.getElementsByClassName("loadingContainer");
            const mainImageGrid = document.getElementsByClassName("mainGalleryFourContainer");
            progressBar[0].style.display = "none";
            mainImageGrid[0].style.display = "block";

        }, 1500);
    }

    delayRender();

    /*jslint eqeq: true*/
    function updateFromRelated(elementURL, element) {
        var big_image = document.getElementById('display_image')
        big_image.src = elementURL
        console.log('updateFromRelated')
        console.log('mainImageOnDisplay:' + mainImageOnDisplay + ' element:' + element.target.id)
        /*jslint eqeq: true*/
        if (mainImageOnDisplay == 1 && element.target.id == 0) {
            setDisplayImageHeading('Maroon (Close Up)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 9 && element.target.id == 0) {
            setDisplayImageHeading('Metropolis (Close Up)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 12 && element.target.id == 0) {
            setDisplayImageHeading('Go Away Gate (Close Up)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 12 && element.target.id == 1) {
            setDisplayImageHeading('Go Away Gate (Alternate View)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 16 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me (Other Side)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 17 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over me Japanese (Other Side)')
            setShowLandscape(false)
        }
        else if (mainImageOnDisplay == 18 && element.target.id == 0) {
            setDisplayImageHeading('Watch Over Me (Close Up)')
            setShowLandscape(false)
        }

    }

    function updateImage(element) {
        console.log("imgae hover called on : " + element.target.id)
        var image = document.getElementById(element.target.id)
        var big_image = document.getElementById('display_image')
        big_image.src = image.src
        setShowLandscape(false)

        switch (element.target.id) {
            case '1':
                setRelatedImages(relatedImages1)
                setCurrentDisplayImage(thumbnail1)
                setMainImageOnDisplay(1)
                setDisplayImageHeading('Maroon')
                setDisplayImageSize('57" x 27" x 12"')
                setDisplayImageMaterial('Steel, Stainless Steel, Paint')
                break;
            case '2':
                setRelatedImages(relatedImages2)
                setCurrentDisplayImage(thumbnail2)
                setMainImageOnDisplay(2)
                setDisplayImageHeading('Double Dancers')
                setDisplayImageSize('24" x 48" x 60"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '3':
                setRelatedImages(relatedImages3)
                setCurrentDisplayImage(thumbnail3)
                setMainImageOnDisplay(3)
                setDisplayImageHeading('Watch Over Me Japanese')
                setDisplayImageSize('108" (9 ft) x 42" x 7"')
                setDisplayImageMaterial('Fiberglass, Stainless Steel, Paint')
                break;
            case '4':
                setRelatedImages(relatedImages4)
                setCurrentDisplayImage(thumbnail4)
                setMainImageOnDisplay(4)
                setDisplayImageHeading('The Ancients')
                setDisplayImageSize('7" x 6" x 4"')
                setDisplayImageMaterial('Distressed Paint Finish')
                break;
            case '5':
                setRelatedImages(relatedImages5)
                setCurrentDisplayImage(thumbnail5)
                setMainImageOnDisplay(5)
                setDisplayImageHeading('Red Flowers')
                setDisplayImageSize('4" x 18"')
                setDisplayImageMaterial('Stainless Steel Brushed and Painted Finish')
                break;
            case '6':
                setRelatedImages(relatedImages6)
                setCurrentDisplayImage(thumbnail6)
                setMainImageOnDisplay(6)
                setDisplayImageHeading('Rythem')
                setDisplayImageSize('36" x 18" x 1"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '7':
                setRelatedImages(relatedImages7)
                setCurrentDisplayImage(thumbnail7)
                setMainImageOnDisplay(7)
                setDisplayImageHeading('Nude Descending')
                setDisplayImageSize('61" x 31" x 1/2"')
                setDisplayImageMaterial('Stainless Steel, Brushed Finish')
                break;
            case '8':
                setRelatedImages(relatedImages8)
                setCurrentDisplayImage(thumbnail8)
                setMainImageOnDisplay(8)
                setDisplayImageHeading('Keep on Trucking')
                setDisplayImageSize('86" x 19" x 4"')
                setDisplayImageMaterial('Stainless Steel')
                break;
            case '9':
                setRelatedImages(relatedImages9)
                setCurrentDisplayImage(thumbnail9)
                setMainImageOnDisplay(9)
                setDisplayImageHeading('Metropolis')
                setDisplayImageSize('34" x 24" x 44"')
                setDisplayImageMaterial('Poll Mount, Rotates and Wobbles on Axis \nStainless Steel Brushed and Painted Finish');
                break;
            case '10':
                setRelatedImages(relatedImages10)
                setCurrentDisplayImage(thumbnail10)
                setMainImageOnDisplay(10)
                setDisplayImageHeading('Sail Dream')
                setDisplayImageSize('43" X 24" 69"')
                setDisplayImageMaterial('Aluminum')
                break;
            case '11':
                setRelatedImages(relatedImages11)
                setCurrentDisplayImage(thumbnail11)
                setMainImageOnDisplay(11)
                setDisplayImageHeading('Bird Brain')
                setDisplayImageSize('6" X 4" 3/8"')
                setDisplayImageMaterial('Stainless Steel ground and brushed finish')
                break;
            case '12':
                setRelatedImages(relatedImages12)
                setCurrentDisplayImage(thumbnail12)
                setMainImageOnDisplay(12)
                setDisplayImageHeading('Go Away Gate')
                setDisplayImageSize('5" X 5" 3"')
                setDisplayImageMaterial('Steel')
                break;
            case '13':
                setRelatedImages(relatedImages13)
                setCurrentDisplayImage(thumbnail13)
                setMainImageOnDisplay(13)
                setDisplayImageHeading('Man Women Blue')
                setDisplayImageSize('15" x 32" x 61"')
                setDisplayImageMaterial('Powder Coated Steel, Granite Base')
                break;
            case '14':
                setRelatedImages(relatedImages14)
                setCurrentDisplayImage(thumbnail14)
                setMainImageOnDisplay(14)
                setDisplayImageHeading('Freebirds with Snake')
                setDisplayImageSize('108" x 22" x 18"')
                setDisplayImageMaterial('Stainless Steel and Wood')
                break;
            case '15':
                setRelatedImages(relatedImages15)
                setCurrentDisplayImage(thumbnail15)
                setMainImageOnDisplay(15)
                setDisplayImageHeading('The Ancients')
                setDisplayImageSize('7" x 6" x 4"')
                setDisplayImageMaterial('Distressed Paint Finish')
                break;
            case '16':
                setRelatedImages(relatedImages16)
                setCurrentDisplayImage(thumbnail16)
                setMainImageOnDisplay(16)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('72" x 28" x 8"')
                setDisplayImageMaterial('Stainless Steel, Aluminum, Fiberglass, Paint')
                break;
            case '17':
                setRelatedImages(relatedImages17)
                setCurrentDisplayImage(thumbnail17)
                setMainImageOnDisplay(17)
                setDisplayImageHeading('Watch Over Me Japanese')
                setDisplayImageSize('108" (9 feet) x 42" x 7"')
                setDisplayImageMaterial('Stainless Steel, Fiberglass, Paint')
                break;
            case '18':
                setRelatedImages(relatedImages18)
                setCurrentDisplayImage(thumbnail18)
                setMainImageOnDisplay(18)
                setDisplayImageHeading('Watch Over Me')
                setDisplayImageSize('72" x 28" x 8"')
                setDisplayImageMaterial('Stainless Steel, Aluminum, Fiberglass, Paint')
                break;

            default:
                break;
        }
    }
    function openFullScreen(element) {
        console.log("full screen called")
        // var image = document.getElementsByClassName('displayImageContainer')
        const DIV = document.querySelector('#display_image');
        if (DIV.requestFullscreen) {
            DIV.requestFullscreen();
        } else if (DIV.msRequestFullscreen) {
            DIV.msRequestFullscreen();
        } else if (DIV.mozRequestFullScreen) {
            DIV.mozRequestFullScreen();
        } else if (DIV.webkitRequestFullscreen) {
            DIV.webkitRequestFullscreen();
        }

        setFullScreen(true)
    }

    function closeFullScreen(element) {
        if (fullScreen) {
            console.log("close full screen called")
            document.exitFullscreen()
            setFullScreen(false)
        }
    }


    function openFullScreenMobile(element) {
        console.log("full screen called")
        console.log(element.target.id)
        const image = document.getElementById(element.target.id)
        image.requestFullscreen()
    }

    return (
        <div className="galleryFour">

            {/* this is the progress bar */}
            <div className="loadingContainer">
                <div className='loadingProgressBar' style={{ width: 120, height: 120 }}>
                    <ChangingProgressProvider values={[0, 100]}>
                        {percentage => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    // pathTransitionDuration: 0.5,
                                    textColor: '#000080',
                                    pathColor: '#396781',
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </div>


            {/*This is for DESKTOP VIEW*/}
            <div className='mainGalleryFourContainer'>
                <div className='galleryFourContainer'>
                    <div className='firstContainer'>
                        <div className='row-tiny'>
                            <img className='imageCard' id='1' onMouseOver={(e) => updateImage(e)} src={thumbnail1} alt='galleryFour'></img>
                            <img className='imageCard' id='5' onMouseOver={(e) => updateImage(e)} src={thumbnail5} alt='galleryFour'></img>
                            <img className='imageCard' id='7' onMouseOver={(e) => updateImage(e)} src={thumbnail7} alt='galleryFour'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='2' onMouseOver={(e) => updateImage(e)} src={thumbnail2} alt='galleryFour'></img>
                            <img className='imageCard' id='6' onMouseOver={(e) => updateImage(e)} src={thumbnail6} alt='galleryFour'></img>
                            <img className='imageCard' id='12' onMouseOver={(e) => updateImage(e)} src={thumbnail12} alt='galleryFour'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='11' onMouseOver={(e) => updateImage(e)} src={thumbnail11} alt='galleryFour'></img>
                            <img className='imageCard' id='13' onMouseOver={(e) => updateImage(e)} src={thumbnail13} alt='galleryFour'></img>
                            <img className='imageCard' id='10' onMouseOver={(e) => updateImage(e)} src={thumbnail10} alt='galleryFour'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='3' onMouseOver={(e) => updateImage(e)} src={thumbnail3} alt='galleryFour'></img>
                            <img className='imageCard' id='8' onMouseOver={(e) => updateImage(e)} src={thumbnail8} alt='galleryFour'></img>
                            <img className='imageCard' id='9' onMouseOver={(e) => updateImage(e)} src={thumbnail9} alt='galleryFour'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='14' onMouseOver={(e) => updateImage(e)} src={thumbnail14} alt='galleryFour'></img>
                            <img className='imageCard' id='15' onMouseOver={(e) => updateImage(e)} src={thumbnail15} alt='galleryFour'></img>
                        </div>
                        <div className='row-tiny'>
                            <img className='imageCard' id='16' onMouseOver={(e) => updateImage(e)} src={thumbnail16} alt='galleryFour'></img>
                            <img className='imageCard' id='17' onMouseOver={(e) => updateImage(e)} src={thumbnail17} alt='galleryFour'></img>
                            <img className='imageCard' id='18' onMouseOver={(e) => updateImage(e)} src={thumbnail18} alt='galleryFour'></img>
                        </div>
                    </div>

                    <div className='secondContainer'>
                        <div className='displayImageContainer'>
                            <div className='displayImageOne'>
                                <img id='display_image' src={currentDisplayImage} onClick={(e) => closeFullScreen(e)} alt='display_image' width={'auto'} height={'auto'} ></img>
                            </div>
                            <div className='displayImageTwo'>
                                <div className='related_images'>
                                    {relatedImage.map((url, index) => {
                                        return <img className='related_images_thumb' id={index} onMouseOver={(e) => updateFromRelated(url, e)} key={index} src={url} alt='related_image' width={'auto'} height={'auto'}></img>
                                    })}
                                </div>
                                <FullscreenIcon id='fullScreenIcon' fontSize="large" onClick={(e) => openFullScreen(e)} />
                                <div id='display_image_information'>
                                    <h3 id='display_image_heading'>{displayImageHeading}</h3>
                                    <h6 id='display_image_size'>{displayImageSize}</h6>
                                    <h6 id='display_image_material'>{displayImageMaterial}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*This is for MOBILE VIEW*/}
            <div className='gallery_mobile'>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_1' src={thumbnail1} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_2' src={thumbnail5} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_3' src={thumbnail7} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_4' src={thumbnail2} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_5' src={thumbnail6} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_6' src={thumbnail12} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_7' src={thumbnail11} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_8' src={thumbnail9} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_9' src={thumbnail10} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_10' src={thumbnail3} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_13' src={thumbnail13} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                    <img className='imageCardMobile' id='mobile_14' src={thumbnail14} onClick={(e) => openFullScreenMobile(e)} alt='galleryTwo'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_15' src={thumbnail15} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_16' src={thumbnail16} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                </div>
                <div className='mobileColumn'>
                    <img className='imageCardMobile' id='mobile_17' src={thumbnail17} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                    <img className='imageCardMobile' id='mobile_18' src={thumbnail18} onClick={(e) => openFullScreenMobile(e)} alt='galleryFour'></img>
                </div>
            </div>
        </div>
    )
}

export default GalleryFour;
